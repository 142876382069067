import React, { useState } from "react";
import { Link as RouteLink } from "react-router-dom";
import {
  Button,
  Container,
  Typography,
  Link,
  GridList,
  GridListTile,
  useMediaQuery,
  useTheme,
  Divider,
  Input,
} from "@material-ui/core";
import "source-sans-pro/source-sans-pro.css";
import NBMap from "./NBMap";
var providerDataJSON = require("./providerData");

function Providers() {
  const providerData: any[] = providerDataJSON;

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const getGridListCols = () => {
    if (matches) {
      return 2;
    }
    return 1;
  };

  var [doctorFilter, setDoctorFilter] = useState("");

  const aStyle = {
    textDecoration: "none",
    fontWeight: 600,
  };

  const buttonStyle = {
    borderRadius: "10px",
    marginTop: "3px",
    padding: "3px 10px",
    color: "black",
    borderWidth: 2,
  };

  var providers = providerData
    .filter(
      (provider) =>
        provider["LastName"].toLowerCase().includes(doctorFilter) |
        provider["FirstName"].toLowerCase().includes(doctorFilter) |
        provider["OrgName"].toLowerCase().includes(doctorFilter)
    )
    .map(function (provider: any) {
      return (
        <GridListTile key={provider["Key"]} style={{ margin: "10px 0" }}>
          <Typography variant='body1' style={{ fontWeight: 600 }}>
            {provider["LastName"]}, {provider["FirstName"]}
          </Typography>
          {/* <Typography variant='body2'>{provider.OrgName}</Typography> */}
          <Typography variant='body2'>
            {provider.City}, {provider.Province}
          </Typography>
          <Typography variant='body1'>
            <a href={provider.URL} style={aStyle}>
              <Button variant='outlined' color='primary' style={buttonStyle}>
                Book Online
              </Button>
            </a>
          </Typography>
        </GridListTile>
      );
    });

  return (
    <React.Fragment>
      <Input
        placeholder='Search by name (3 characters minimum)'
        style={{ width: 400, paddingTop: 15 }}
        onChange={(e) =>
          setDoctorFilter(
            (doctorFilter = e.target.value.length >= 3 ? e.target.value : "")
          )
        }
      ></Input>

      <GridList
        cellHeight='auto'
        cols={getGridListCols()}
        style={{ margin: "20px 0" }}
      >
        {providers}
      </GridList>
      {providers.length === 0 ? (
        <Typography>No Doctors Found</Typography>
      ) : null}
    </React.Fragment>
  );
}

function Home() {
  return (
    <Container maxWidth='md'>
      <div style={{ display: "flex" }}>
        <div>
          <Typography variant='h1'>
            Welcome
          </Typography>
          <Typography variant='body1'>
            My Health Link connects you to your doctor’s office by offering
            online appointment booking. This is a safe, convenient way to
            schedule an appointment – even when the clinic is closed. For more
            information, please visit our{" "}
            <RouteLink to='/about-us'>
              <Link>Frequently Asked Questions</Link>
            </RouteLink>.
          </Typography>
        </div>
        <div>
          <NBMap />
        </div>
      </div>

      <Typography variant='h2' style={{ marginBottom: 5 }}>
        Find My Doctor
      </Typography>
      <Divider style={{ color: "primary" }} />

      <Container maxWidth='xl'>
        <Providers />
      </Container>
    </Container>
  );
}

export default Home;
