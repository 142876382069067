import React from "react";
import "source-sans-pro/source-sans-pro.css";
import "./NBMap.css";

function NBMap() {
  const [selectedRegion, setSelectedRegion] = React.useState("");

  const handleClickOpen = (e: any) => {
    // setOpen(true);
    //setDialogText(e.target.id);
    // e.target.id === selectedRegion ?  setSelectedRegion("") : setSelectedRegion(e.target.id)
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
      <svg id="map" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="300" viewBox="0 0 1409 1012">
      <defs>
        </defs>
        <path id="stjohn" onClick={handleClickOpen} className={`cls-1 land ${selectedRegion ? selectedRegion === "stjohn" ? "selected" : "unselected" : ""}`} d="M956,719c3,8.332,7,18.668,10,27-26.617,4.163-78.254,40.413-88,63-23.615.692-77.23,32.928-91,27-0.541-3.829-2.972-20.022-9-17-8.577,4.3-12.071,22.925-18,30h-2c-11.241,10.262-23.642,4.924-37,12-7.931,4.2-9.114,12.015-21,14l-5-6c2.745-8.683,2.17-21.189,2-35C782.325,796.337,870.675,756.663,956,719Z"/>
        <path id="kings" onClick={handleClickOpen} className={`cls-2 land ${selectedRegion ? selectedRegion === "kings" ? "selected" : "unselected" : ""}`} d="M911.2,589.547C915.028,600.064,951.4,704.038,957,719c-85.325,37.33-174.675,77.67-260,115,0-8,1-17,1-25C768.327,737.006,840.877,661.535,911.2,589.547Z"/>
        <path id="stcroix" onClick={handleClickOpen} className={`cls-3 land ${selectedRegion ? selectedRegion === "stcroix" ? "selected" : "unselected" : ""}`} d="M698,756c-0.333,35-.667,72-1,107-1.862-2.048-4.614-3.339-7-5-14.539,5.529-32.567,31.583-52,24-3.149-1.812-2.578-1.437-4-5-5.106,1.256-8.282,4.7-12,6-13.371,4.662-11.24-22.752-16-28-2.194-2.9-3.364-3.341-8-4l-9,6c-0.378,6.713-1.592,13.585-4,18h-5c-5.649-9.658-9.74-30.676-19-37h-1c-1.062,6.75-4.019,8.592-7,13-13.936-.535-19.191,2.051-26,8l-4-2c-11.984-31.839-30.092-21.276-12-65-5.087-10.709-11.417-17.893-13-33,0.333-.333-1.333-1.667-1-2C562.66,757,632.34,756,698,756ZM599,885a24.636,24.636,0,0,1,11,2q0.5,1,1,2c-3.526,9.3-4.365,17.444-10,24l-10-1C590.889,898.634,594.757,893.108,599,885Zm12,27c4.406,0.112,4.935-.091,7,2,3.481,8.574-3.733,16.691-6,24-5.966,1.737-9.236-.064-13-3C596.435,920.319,606.786,921.629,611,912Zm1,96c-0.108-22.528,11.9-41.648,20-57,3.651-.912,2.045.038,4-2,10.291,2.944,11.5,26.63,13,37-10.281,9.775-24.166,6.918-30,22h-7Z"/>
        <path id="victoria" onClick={handleClickOpen} className={`cls-4 land ${selectedRegion ? selectedRegion === "victoria" ? "selected" : "unselected" : ""}`} d="M446,633c-0.173-100.848-5.483-214.376,5-307l13-2c-0.268-11.837.5-21.484,0.373-31C464.11,272.619,463.508,203.77,464,187c40,19.665,82,39.335,122,59l28,65c3.925,9.013,25.98,49.923,23,55L526,568c-12.3,21-27.61,40.723-34,67l-5,2c-4.567-3.63-5.513-6.161-8-12H461l-7,8h-8Z"/>
        <path id="capital" onClick={handleClickOpen} className={`cls-5 land ${selectedRegion ? selectedRegion === "capital" ? "selected" : "unselected" : ""}`}  d="M698,495l198,53c4.666,14,10.334,27,15,41-69.993,71.659-141.007,144.341-210.386,217.021A14.076,14.076,0,0,0,698,809c-0.03-16.959-1.152-35.177-1-52.381C698.667,754,687.333,756,687,756c-62.994.333-115.006-.333-178,0,0.64-17.17-4.532-28.1-8-40-4.631,2.148-4.823,3.845-8,7-6.137-.421-14.391,2.164-20,0-9.618-3.71-8.21-13.168-23-14-0.69-13.872-1.884-20.828-11-28,0.963-11.987,10.04-13.268,2-20l-2-5c2.465-14.925,16.2-21.173,25-30l13,1c4.558,5.825,6.9,13.275,15,10,8.021-31.574,30.984-64.593,47.487-92.575C572.663,486.673,639.337,365.452,639,365"/>
        <path id="miramichi" onClick={handleClickOpen} className={`cls-6 land ${selectedRegion ? selectedRegion === "miramichi" ? "selected" : "unselected" : ""}`} d="M733,190c2.666,0,4.334-2,7-2,6.3,8.433,68.313,83.6,75,85,11.4,3.317,36.874-11.9,48-16l130-46c0.086,17.255-5.237,43.879-14,54l-18,12c-19.6,22.172-12.014,22.118-43,36v4l1,1,20-2,3,8c10.132-5.516,16.848-11.206,34-10l11,3c5.807-1.5,6.833-5.555,16-5,0,1.333,2,2.667,2,4-16.823,15.072-43.343,37.546-52.642,46.124C940.42,372.081,891.33,415.669,861,442c-4.312,7.978-16,28.335-25,44-7.326,12.351-15.767,27.426-23.133,39.658C774.754,515.157,736,504,698,494c-37.33-83.325-75.67-166.675-113-250"/>
        <path id="restigouche" onClick={handleClickOpen} className={`cls-7 land ${selectedRegion ? selectedRegion === "restigouche" ? "selected" : "unselected" : ""}`} d="M703,44c14.187,2.336,19.043,17.345,30,23,19.5,10.063,43.423,16.22,66,13,0,3.333-1,22.667-1,26-4.132,7.38-35.991,68.572-40,75L656,217c-10.358,4.01-64.914,28.769-74,26L335,121c1.856-8.338-2.07-29.39,4-34,9.261-7.811,34.566,2.459,43-6q1-9,2-18c32.2-.1,62.322.932,96,1,4.281,19.066,24.98,35.1,43,39,6.857-4.338,57.753-23.093,68-20,4.659,1.406,5.36,4.879,12,6V87c2.013-5.238,2.552-13.829,4-18C639.564,69.848,689.325,59.9,703,44Z"/>
        <path id="madawaska" onClick={handleClickOpen} className={`cls-8 land ${selectedRegion ? selectedRegion === "madawaska" ? "selected" : "unselected" : ""}`} d="M337,124l126,61c0.568,42.483,4.083,134.122-5,138-2.543-2.565-6.539-1.969-10,0,0.219,3.33.933,2.3-1,5l-3-2c-0.627-4.85-58.2-69.578-65-75-4.141-3.3-27.421-15.584-30-12-9.411,4.119-8.481,17.141-15,24-13.155-.873-21.944-6.561-35,0l-15,12-20,2c-8.393,4.12-14,11.519-24,14-9.161-11.519-31.533-16.94-33-35h2l45-9c26.188-11.928,78.036-37.445,85-66Q338,152.5,337,124Z"/>
        <path id="moncton" onClick={handleClickOpen} className={`cls-9 land ${selectedRegion ? selectedRegion === "moncton" ? "selected" : "unselected" : ""}`} d="M1043,624c1.18,13.7,10.08,19.139,17,27-0.9,5.666-.5,8.961-1,13-7.32,2.964-7.6,9.964-13,13-3.53,1.985-7.44.062-10,2,0.33,1,.67,2,1,3l11,3v6l-8,1c0.17,5.639,1.57,10.825-4,13-3.86,5.2-9.74,8.509-14,14-3.66-2.729-5.25-3.821-12-4l-9,8-8-8-2,2c-2.078,2.024-1.365,1-2,5,2.07,3.883,3.327,5.862,2,11-13.131-.326-16.094,4.346-20,13h-4c-24.177-66.188-48.353-133.15-71.066-198.342C867.669,540.667,840.331,532.333,812,525c17-28.33,35-57.669,52-86l143-123c0.67,0.333,1.33.667,2,1-1.32,13.757,2.7,19.762,3,34q-11.5,12-23,24c0.8,14.645,7.9,34.659,15,44,7.61-.522,16.59-4.043,21,0,2.33,1.666,4.67,3.334,7,5,0.8,44.253,13.01,55.244,24,89a19.214,19.214,0,0,1,12-1c2.46,7.141,5.37,9.03,4,19-7.72,2.906-11.55,6.29-10,16,1.92,1.392.6,0.1,2,2,16.63,0.3,43.15-4.617,64,2,7.6,2.412,13.08,9.8,19,12,8.15,3.025,14.82-3.392,21-4,12.48-1.228,42.81,9.2,48,17,6.52,22.892-44.67,17.064-54,29-0.67,1-1.33,2-2,3,2.74,6.778,7.83,6.272,10,14-0.33.667-.67,1.333-1,2q-9.495-2.5-19-5-14.505,18.5-29,37-4.005-2.5-8-5c-7.35,15.67-23.15,20.445-31,42-4.83.212-7.77,0.246-10-2q3-17,6-34c-5.41-1.426-8.32-2.446-9-9q2.505-5,5-10c-2.06-2.212-1.38-1.882-5-3l-6,6c-4.3-1.972-6.71-10.28-9-14Z"/>
        <path id="acadie" onClick={handleClickOpen} className={`cls-10 land ${selectedRegion ? selectedRegion === "acadie" ? "selected" : "unselected" : ""}`}  d="M1059,49c4.55-.033,5.89-0.132,8,2,5.14,2.748,9.87,12.967,6,21-4.76,10.183-18.03,10.126-25,18,0.33,1,.67,2,1,3,0.67,0.333,1.33.667,2,1,4.87,1.013,8.09.7,12-1,0.67,0.333,1.33.667,2,1-0.76,14.986-15.71,37.458-30,39-2.07-3.564-2.13-3.228-6-5a197.806,197.806,0,0,0-2-31q9.495-5,19-10C1045.46,67.25,1051.88,60.618,1059,49ZM800,80c18.612,7.76,33.808,22,38,44,3.03,15.9,1.065,21.4,8,31h1l33-3c8.8-24.515,54.7-44.771,90-45q2.5,5.5,5,11c6.6-4.578,12.824-7.819,24-8q6.5,5,13,10c-1.78,6.572-4.39,7.763-8,12,1.15,3.273,1.38,2.718,3,5l11,2q3.5-5,7-10l4,1c0.67,3,1.33,6,2,9-2.53,8.022-5.12,10.138-12,6-3.5,14.5-12.17,27-18,41l-7,24c-44.995,15.665-88,33.335-133,49-9.261,3.628-42.823,16.149-46.558,17.3-13.036-14.948-15.305-18.434-18.249-21.969C779,232,758,210,739,188c4.833-1.275,19.836-7.693,20-8,0.685-1.282,35.693-68.8,38.48-73.932C797.916,105.265,799.551,86.508,800,80Z"/>
        <path className="cls-11" d="M814,525l50-86,149-129"/>
        <path className="cls-11" d="M740,189l75,87,180-66"/>
        <path className="cls-11" d="M799,79l-2,26-39,76L585,244,331,121"/>
        <path className="cls-11" d="M463,185V322l-13,3-2,11"/>
        <path className="cls-11" d="M585,244L698,494l199,54,70,200"/>
        <path className="cls-11" d="M494,756H697V878"/>
        <path className="cls-11" d="M697,833L957,719"/>
        <path className="cls-11" d="M697,809L912,589"/>
        <path className="cls-11" d="M639,365L494,626s-0.812,10.938-2,10c-1.281.375-1.375,1.063-4,1-4.219.406-10-11-10-11H462l-9,8-10,7"/>
        <path className="cls-12 line" d="M985,550l102-93h99"/>
        <circle cx="985" cy="550" r="20"/>
        <circle className="cls-13" cx="985" cy="550" r="18"/>
        <circle className="cls-14" cx="985" cy="550" r="11" style={{pointerEvents: "none"}}/>
        <text id="Moncton-2" data-name="Moncton" className="cls-15" x="1199.362" y="467.666">Moncton</text>
        <path className="cls-12 line" d="M808,821l57,57h51"/>
        <circle cx="808" cy="821" r="20"/>
        <circle className="cls-13" cx="808" cy="821" r="18"/>
        <circle className="cls-14" cx="808" cy="821" r="11"/>
        <text id="Saint_John-2" data-name="Saint John" className="cls-15" x="927.78" y="888.334">Saint John</text>
        <path className="cls-12 line" d="M832,726l78,78,134,1"/>
        <circle cx="832" cy="726" r="20"/>
        <circle className="cls-13" cx="832" cy="726" r="18"/>
        <circle className="cls-14" cx="832" cy="726" r="11"/>
        <text id="Kings_County-2" data-name="Kings County" className="cls-15" x="1066.071" y="815.333">Kings County</text>
        <path className="cls-12 line" d="M596,815l-30-30H394"/>
        <circle cx="596" cy="814" r="20"/>
        <circle className="cls-13" cx="596" cy="814" r="18"/>
        <circle className="cls-14" cx="596" cy="814" r="11"/>
        <text id="St._Croix-2" data-name="St. Croix" className="cls-16" x="267.837" y="793">St. Croix</text>
        <path className="cls-12 line" d="M514,475l-62-63H303"/>
        <circle cx="514" cy="475" r="20"/>
        <circle className="cls-13" cx="514" cy="475" r="18"/>
        <circle className="cls-14" cx="514" cy="475" r="11"/>
        <text id="Victoria_and_Carleton-2" data-name="Victoria and Carleton" className="cls-16" x="124.649" y="420.333">Victoria and<tspan x="124.649" dy="35.2">Carleton</tspan></text>
        <path className="cls-12 line" d="M653,642l-63-63H392"/>
        <circle cx="653" cy="641" r="20"/>
        <circle className="cls-13" cx="653" cy="641" r="18"/>
        <circle className="cls-14" cx="653" cy="641" r="11"/>
        <text id="Capital_Region-2" data-name="Capital Region" className="cls-16" x="182.95" y="586">Capital Region</text>
        <path className="cls-12 line" d="M838,349l87-87h143"/>
        <circle cx="838" cy="349" r="20"/>
        <circle className="cls-13" cx="838" cy="349" r="18"/>
        <text id="Miramichi-2" data-name="Miramichi" className="cls-15" x="1080.834" y="274.167">Miramichi</text>
        <path className="cls-12 line" d="M850,212L994,68h148"/>
        <circle cx="850" cy="211" r="20"/>
        <circle className="cls-13" cx="850" cy="211" r="18"/>
        <circle className="cls-14" cx="850" cy="211" r="11"/>
        <text id="Acadie-Bathurst-2" data-name="Acadie-Bathurst" className="cls-15" x="1158.5" y="75.5">Acadie-Bathurst</text>
        <path className="cls-12 line" d="M630,166L496,32H389"/>
        <circle cx="630" cy="167" r="20"/>
        <circle className="cls-13" cx="630" cy="167" r="18"/>
        <circle className="cls-14" cx="630" cy="167" r="11"/>
        <text id="Restigouche-2" data-name="Restigouche" className="cls-16" x="208.615" y="38.667">Restigouche</text>
        <path className="cls-12 line" d="M420,242l-90-87H222"/>
        <circle cx="420" cy="241" r="20"/>
        <circle className="cls-13" cx="420" cy="241" r="18"/>
        <circle className="cls-14" cx="420" cy="241" r="11"/>
        <text id="Madawaska_including_Saint-Queintin_and_Kedhwick_2" data-name="Madawaska (including Saint-Queintin and Kedhwick)" className="cls-16" x="41.664" y="162.667">Madawaska<tspan className="cls-18" x="41.664" dy="25.6">(including Saint-Queintin</tspan><tspan className="cls-18" x="41.664" dy="25.6">and Kedhwick)</tspan></text>
      </svg>
  );
}

export default NBMap;