import React, { useEffect } from "react";
import "./App.css";
import {
  Typography,
  Link,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import "source-sans-pro/source-sans-pro.css";
import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import Privacy from "./Privacy";
import {
  BrowserRouter as Router,
  Route,
  Link as RouteLink,
  Switch,
} from "react-router-dom";
import Header from "./Header";
import NotFound from "./NotFound";

function App() {
  const theme = createMuiTheme({
    typography: {
      fontFamily: "source sans pro",
      h1: {
        color: "#009ee0",
        fontSize: "42px",
        marginBottom: "20px",
        textTransform: "uppercase"
      },
      h2: {
        color: "#009ee0",
        fontSize: "28px",
        margin: "20px 0",
        textTransform: "uppercase"
      },
      h3: {
        color: "#3c3835",
        fontSize: "20px",
        fontStyle: "italic",
        margin: "25px 0 10px 0",
        fontWeight: 600,
      },
      body1: {
        fontSize: "20px",
        fontWeight: 300,
      },
      body2: {
        fontSize: "18px",
        fontWeight: 300,
      }
    },
    palette: {
      primary: {
        main: "#009ee0",
      },
      secondary: {
        main: "#009ee0",
      },
    },
  });

  return (
    <Router basename='/'>
      <ThemeProvider theme={theme}>
        <div style={{ minHeight: "100vh", position: "relative" }}>
          <div style={{ paddingBottom: "300px" }}>
            <Header />

            <Switch>
              <Route exact path='/' component={Home} />
              <Route path='/about-us' component={About} />
              <Route path='/account-set-up-help' component={Contact} />
              <Route path='/privacy' component={Privacy} />
              <Route component={NotFound} />
            </Switch>

            <footer
              style={{
                background: "#009ee0",
                color: "#FFF",
                textAlign: "center",
                padding: "100px 0 70px 0",
                bottom: 0,
                position: "absolute",
                width: "100%",
              }}
            >
              <Typography variant='body1' gutterBottom>
                © 2020 Intrahealth
              </Typography>
              <Typography variant='body1' gutterBottom>
                <RouteLink to='/privacy'>
                  <Link style={{ color: "#FFFFFF " }}>Privacy Policy</Link>
                </RouteLink>
              </Typography>
            </footer>
          </div>
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;
