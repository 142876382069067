import React from "react";
import { Container, Link, Typography } from "@material-ui/core";
import "source-sans-pro/source-sans-pro.css";

function About() {
  return (
    <Container maxWidth='md'>
      <Typography variant='h1'>
        About
      </Typography>
      <Typography variant='body1'>
        My Health Link connects you to your doctor’s office by offering online
        appointment booking. This is a safe, convenient way to schedule an
        appointment – even when the clinic is closed.
      </Typography>
      <Typography variant='body1'>
        My Health Link is brought to you by Intrahealth. Please see{" "}
        <Link href="https://www.intrahealth.com/">Intrahealth.com</Link> for more information.
      </Typography>
      <Typography variant='h2'>
        Frequently Asked Questions
      </Typography>
      <Typography variant='h3'>
        What is My Health Link?
      </Typography>
      <Typography variant='body1'>
        My Health Link is an online service which makes it possible for patients
        to book their doctor’s appointment online.
      </Typography>
      <Typography variant='h3'>
        {" "}
        What is an EMR?
      </Typography>
      <Typography variant='body1'>
        EMR stands for Electronic Medical Record. This technology replaces paper
        charts traditionally used by doctors, making your health information
        available electronically.
      </Typography>

      <Typography variant='body1'>
        Benefits include:
        <ul>
          <li>Faster access to your test results</li>
          <li>Electronic graphs of your medical history</li>
          <li>
            Allows the physician to access your medical information online
            anywhere, anytime
          </li>
          <li>Alerts the physician of conflicting prescriptions</li>
        </ul>
      </Typography>

      <Typography variant='h3'>
        How do I know if my doctor offers My Health Link?
      </Typography>
      <Typography variant='body1'>
        Not all physicians in New Brunswick offer e-booking. Ask your physician
        if they offer the service.
      </Typography>
      <Typography variant='h3'>
        I’d like to book my appointment online. How do I get started?
      </Typography>
      <Typography variant='body1'>
        Contact your doctors’ office for a username and password. This brochure
        will help you get started once you have information to log in.
      </Typography>
      <Typography variant='h3'>
        Is my information safe?
      </Typography>
      <Typography variant='body1'>
        Absolutely. The EMR follows regulations and standards which are stricter
        than those for online banking. This level of security applies to My
        Health Link as well.
      </Typography>
      <Typography variant='h3'>
        Why doesn’t my doctor offer this service?
      </Typography>
      <Typography variant='body1'>
        Not all doctors choose to use the e-booking feature available to them.
        If you would like this service to be available at your physician’s
        clinic, we encourage you to mention your interest during your next
        visit. It may be something the clinic is considering.
      </Typography>
      <Typography variant='h3'>
        I’m trying to book online but it won’t work.
      </Typography>
      <Typography variant='body1'>
        Are you using an old internet browser? This may be the issue. Try
        updating your browser then try again. If it’s still not working, contact
        your clinic.
      </Typography>
      <Typography variant='h3'>
        Does this mean I can access my medical record online?
      </Typography>
      <Typography variant='body1'>
        Not all doctors choose to use the full online medical record
        functionality that is available. If you would like this service to be
        available at your physician’s clinic, we encourage you to mention your
        interest during your next visit. It may be something the clinic is
        considering
      </Typography>
    </Container>
  );
}

export default About;
