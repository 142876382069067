import React from "react";
import { Container, Typography } from "@material-ui/core";
import "source-sans-pro/source-sans-pro.css";

function Privacy() {
  return (
    <Container maxWidth='md'>
      <Typography variant='h1'>
        Privacy Policy
      </Typography>
      <Typography variant='body1'>
        This Privacy Policy describes how Intrahealth Systems Limited (“we”,
        “us” or “Intrahealth”) may collect, use, and share information about you
        (“you” or “user”) that we obtain through myhealthlink.ca (“MyHealthLink
        Website”). By using the MyHealthLink Website, you consent to this
        Privacy Policy and the terms herein. Intrahealth may change this Privacy
        Policy from time to time. It is your responsibility to check this
        webpage periodically to see if any terms have been changed or modified.
        Your continued use of the MyHealthLink Website constitutes your
        acceptance of any updates to this Privacy Policy.
      </Typography>
      <Typography variant='h2'>
        How does Intrahealth collect your information?
      </Typography>
      <Typography variant='body1'>
        Intrahealth collects information from you in three ways: (1) directly
        from you, (2) from Intrahealth web server logs, and (3) cookies.
        <ol>
          <li>
            Information That You Give Us: we obtain information about you when
            you use the MyHealthLink Website, when you contact us about products
            and services, when send us a message, or when you choose (opt-in) to
            subscribe to our blog, newsletter, or event notification emails. In
            these cases, you decide what and how much information you want to
            share.
          </li>
          <li>
            Intrahealth Web Server Logs: an IP address is a number automatically
            assigned to your computer whenever you access the Internet. IP
            addresses allow computers and servers to recognize and communicate
            with each other. Intrahealth collects IP addresses in order to
            conduct system administration and site analysis, to improve
            functionality and usage of the MyHealthLink Website.
          </li>
          <li>
            Cookies: for information about cookies, see Intrahealth’s Cookie
            Policy.
          </li>
        </ol>
      </Typography>
      <Typography variant='h2'>
        What information is Intrahealth collecting?
      </Typography>
      <Typography variant='body1'>
        The information we collect might include:
        <ul>
          <li>IP address</li>
          <li>Browser type and version</li>
          <li>
            Data about the equipment used to visit the MyHealthLink Website
          </li>
          <li>
            Patterns of searching and browsing on the MyHealthLink Website
          </li>
        </ul>
      </Typography>
      <Typography variant='h2'>
        How does Intrahealth use your information?
      </Typography>
      <Typography variant='body1'>
        We may use your information:
        <ul>
          <li>
            to improve the functionality and usage of the MyHealthLink Website
          </li>
          <li>
            to facilitate Intrahealth’s own marketing, promotional, and
            informational purposes
          </li>
          <li>
            to track the popularity of features on the MyHealthLink Website and
            to guide the development of new ones
          </li>
          <li>
            to identify the types of devices our visitors use so that we can
            improve and optimize our systems
          </li>
        </ul>
      </Typography>
      <Typography variant='h2'>
        Will Intrahealth share your information?
      </Typography>
      <Typography variant='body1'>
        We will never sell, trade, or rent your personal identification
        information to others.
        <br /> <br />
        Intrahealth engages certain service providers, such as Google Analytics,
        for purposes of tracking and associating Internet search and browsing
        behavior with our advertisements, and to provide functionality on the
        MyHealthLink Website. We enable these service providers to use tracking
        technologies on, or in conjunction with, the website. The use and
        collection of information by these service providers is governed by
        their respective privacy statements, and thus is not covered by this
        Policy. To read more about their respective policies, check out Google
        Analytics Terms of Service and Google Privacy Policy.
        <br /> <br />
        In addition, we may share MyHealthLink Website usage information with
        these service providers, who are responsible for hosting the data, to
        identify trends and determine market focus. Information obtained through
        these processes may be combined with Personally Identifiable Information
        (such as name, address and phone number) in order to analyze our
        marketing efforts. We will only share Personally Identifiable
        Information with third party vendors, consultants, agents, partners, and
        other service providers with whom we contract to help us provide or
        improve Intrahealth services.
      </Typography>
      <Typography variant='h2'>
        Choice / Opt-In
      </Typography>
      <Typography variant='body1'>
        Automated communication is restricted to only information you have
        requested, such as reminders for an upcoming event or webinar, or
        delivery of a white paper or case study. Should you desire more regular
        communication from Intrahealth, you can elect to subscribe to our blog,
        newsletter, or event notification emails. All of these features also
        include an opt-out function, so you can unsubscribe at any time.
      </Typography>
      <Typography variant='h2'>
        How does Intrahealth protect your information?
      </Typography>
      <Typography variant='body1'>
        The security of your information and data while using the MyHealthLink
        Website is very important to us. We implement appropriate data
        collection, storage, and processing practices and security measures to
        protect against unauthorized access, alteration, disclosure, or
        destruction of your personal information, username, password,
        transaction information and data stored on the MyHealthLink Website.
        <br /> <br />
        Sensitive and private data exchange between the MyHealthLink Website and
        its users is encrypted and protected through an SSL secured
        communication channel. Intrahealth keeps up to date with the latest
        encryption protocols, and does not support outdated and vulnerable
        protocols.
      </Typography>
      <Typography variant='h2'>
        Third-party links
      </Typography>
      <Typography variant='body1'>
        The MyHealthLink Website may contain links to third-party websites.
        These websites are governed by their own respective privacy policies. We
        encourage you to review the privacy policies of third-party websites to
        understand their data and information collection, as well as use
        practices.
      </Typography>
      <Typography variant='h2'>
        Contact Information
      </Typography>
      <Typography variant='body1'>
        If you have any questions about this Privacy Policy or our treatment of
        your information, please email privacy@intrahealth.com.
      </Typography>
    </Container>
  );
}

export default Privacy;
