import React from "react";
import "./App.css";
import { Button, Container, Link } from "@material-ui/core";
import "source-sans-pro/source-sans-pro.css";
import logo from "./images/logo.png";
import banner from "./images/banner.jpg";
import { useLocation, Link as RouteLink } from "react-router-dom";

function getFrenchLocation(englishLocation: string): string {
  let frenchDomain = "https://maconnexionsante.ca";
  if (englishLocation === "/") {
    return frenchDomain + "/";
  } else if (englishLocation === "/about-us") {
    return frenchDomain + "/au-sujet-de-nous";
  } else if (englishLocation === "/account-set-up-help") {
    return frenchDomain + "/configuration-de-votre-compteaide";
  } else if (englishLocation === "/privacy") {
    return frenchDomain + "/politique-de-confidentialite-de-velante";
  }
  return frenchDomain;
}

function Header() {
  let location = useLocation();

  return (
    <>
      <Container maxWidth='md' id='header'>
        <div style={{ display: "block", flex: 1000 }}>
          <RouteLink to='/'>
            <Button style={{ backgroundColor: "transparent" }}>
              <img src={logo} alt='logo' className='App-logo' />
            </Button>
          </RouteLink>
        </div>
        <div id='menu'>
          <div id='menu-spacer'></div>
          <RouteLink to='/about-us'>
            <Button>About Us</Button>
          </RouteLink>
          <RouteLink to='/account-set-up-help'>
            <Button>Contact</Button>
          </RouteLink>
          <Link href={getFrenchLocation(location.pathname)}>
            <Button>Français</Button>
          </Link>
        </div>
      </Container>
      <Container maxWidth='xl' style={{ padding: 0, marginBottom: "50px" }}>
        <section
          style={{
            backgroundImage: `url(${banner})`,
            backgroundPosition: "center",
            height: "172px",
          }}
        />
      </Container>
    </>
  );
}

export default Header;
