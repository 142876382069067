import React from "react";
import { Container, Typography } from "@material-ui/core";
import "source-sans-pro/source-sans-pro.css";

function Contact() {
  return (
    <Container maxWidth='md'>
      <Typography variant='h1'>
        Account Set-Up/Help
      </Typography>
      <Typography variant='body1'>
        If you would like a My Health Link account, contact your doctors’ office
        for a username and password. Please note that not all doctors offer this
        service.
      </Typography>
      <Typography variant='body1'>
        If you need help with your My Health Link account, you will need to call
        your doctor’s office.
      </Typography>
    </Container>
  );
}

export default Contact;
